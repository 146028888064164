import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getData, storageKey } from '../constants/storage';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';

function Changecontent() {
  const [footerData, setFooterData] = useState(null); 
  const [headerData, setHeaderData] = useState(null); 
  const [selectedFooterImage, setSelectedFooterImage] = useState(null); 
  const [selectedHeaderImage, setSelectedHeaderImage] = useState(null); 
  const [previewFooterImage, setPreviewFooterImage] = useState(null); 
  const [previewHeaderImage, setPreviewHeaderImage] = useState(null); 
  const [loading, setLoading] = useState(false); 
  const [message, setMessage] = useState(''); 
  const [openSnackbar, setOpenSnackbar] = useState(false); 

  const BASE_URL = 'https://api.jodohealth.com'; 
  const token = getData(storageKey.AUTH_TOKEN);

  const fetchFooterAndHeaderData = async () => {
    try {
      const footerResponse = await axios.get(`${BASE_URL}/api/upload-footer/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFooterData(footerResponse.data.body.findAllFooter[0]); 

      const headerResponse = await axios.get(`${BASE_URL}/api/upload-header/all`, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      setHeaderData(headerResponse.data.body.findAllHeader[0]);
    } catch (error) {
      console.error('Error fetching footer or header data:', error);
    }
  };

 
  useEffect(() => {
    fetchFooterAndHeaderData();
  }, [token]); 

  const handleFooterImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFooterImage(file);
    setPreviewFooterImage(URL.createObjectURL(file)); 
  };


  const handleHeaderImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedHeaderImage(file); 
    setPreviewHeaderImage(URL.createObjectURL(file)); 
  };

 
  const handleFooterImageUpload = async (e) => {
    e.preventDefault();
    if (!selectedFooterImage) {
      setMessage('Please select a footer image to upload.');
      setOpenSnackbar(true);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('avatar', selectedFooterImage);

    try {
      const footerId = footerData?.id;
      await axios.post(
        `${BASE_URL}/api/upload-footer/update?footerId=${footerId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      setMessage('Footer image updated successfully!');
      fetchFooterAndHeaderData();
      setOpenSnackbar(true);
      setFooterData((prevData) => ({
        ...prevData,
        avatar: previewFooterImage, 
      }));
      setSelectedFooterImage(null); 
      setPreviewFooterImage(null); 
    } catch (error) {
      setMessage('Error updating the footer image.');
      setOpenSnackbar(true);
      console.error('Error uploading footer image:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleHeaderImageUpload = async (e) => {
    e.preventDefault();
    if (!selectedHeaderImage) {
      setMessage('Please select a header image to upload.');
      setOpenSnackbar(true);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('avatar', selectedHeaderImage);

    try {
      const headerId = headerData?.id;
      await axios.post(
        `${BASE_URL}/api/upload-header/update?headerId=${headerId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      setMessage('Header image updated successfully!');
      fetchFooterAndHeaderData();
      setOpenSnackbar(true);
      setHeaderData((prevData) => ({
        ...prevData,
        avatar: previewHeaderImage, 
      }));
      setSelectedHeaderImage(null); 
      setPreviewHeaderImage(null); 
    } catch (error) {
      setMessage('Error updating the header image.');
      setOpenSnackbar(true);
      console.error('Error uploading header image:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" gutterBottom>
        Manage Header and Footer Images
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Update Header Image  (1268 x 398 Pixels)
          </Typography>
          {previewHeaderImage ? (
            <CardMedia
              component="img"
              image={previewHeaderImage} 
              alt="Selected Header Preview"
              sx={{ width: '80%', height: 'auto', maxHeight: 150, marginBottom: 2, objectFit: 'cover' }} 
            />
          ) : (
            headerData && (
              <CardMedia
                component="img"
                image={`${BASE_URL}${headerData.avatar}`} 
                alt="Header Avatar"
                sx={{ width: '80%', height: 'auto', maxHeight: 150, marginBottom: 2, objectFit: 'cover' }} 
              />
            )
          )}

          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="headerAvatar"
            type="file"
            onChange={handleHeaderImageChange}
          />
          <label htmlFor="headerAvatar">
            <Button variant="contained" component="span" sx={{ marginBottom: 2 }}>
              Upload New Header Image
            </Button>
          </label>

          <Button variant="contained" onClick={handleHeaderImageUpload} disabled={loading} sx={{ marginBottom: 2 ,marginLeft :3}}>
            {loading ? 'Uploading...' : 'Update Header'}
          </Button>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ marginBottom: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Update Footer Image  (1145 x 179 Pixels)
          </Typography>
          {previewFooterImage ? (
            <CardMedia
              component="img"
              image={previewFooterImage} // Show the preview of the selected footer image
              alt="Selected Footer Preview"
              sx={{ width: '80%', height: 'auto', maxHeight: 150, marginBottom: 2, objectFit: 'cover' }} // Adjust preview size
            />
          ) : (
            footerData && (
              <CardMedia
                component="img"
                image={`${BASE_URL}${footerData.avatar}`} // Show the current footer image
                alt="Footer Avatar"
                sx={{ width: '80%', height: 'auto', maxHeight: 150, marginBottom: 2, objectFit: 'cover' }} // Adjust image size
              />
            )
          )}

          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="footerAvatar"
            type="file"
            onChange={handleFooterImageChange}
          />
          <label htmlFor="footerAvatar">
            <Button variant="contained" component="span" sx={{ marginBottom: 2 }}>
              Upload New Footer Image
            </Button>
          </label>

          <Button variant="contained" onClick={handleFooterImageUpload} disabled={loading} sx={{ marginBottom: 2 ,marginLeft :3 }}>
            {loading ? 'Uploading...' : 'Update Footer'}
          </Button>
        </CardContent>
      </Card>



      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Changecontent;
