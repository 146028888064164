import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, ArcElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
import AppWidgetSummary from './layout/app-widget-summary';
import usericon from "./glass/ic_glass_users.png";
import { getDashboarddata } from '../../../redux/services/otherServices/Dashboard';
import { getData, storageKey } from '../../../constants/storage';

ChartJS.register(LineElement, PointElement, ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const navigationUrls = {
  users: 'https://api.jodohealth.com/api/user/download/xml',
  appointment: 'https://api.jodohealth.com/api/user/appointment/download/xml'
};

export default function AppView() {
  const [data, setData] = useState(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    const token = getData(storageKey.AUTH_TOKEN);
    setToken(token);
    handleGetDashboardData();
  }, []);

  const handleGetDashboardData = async () => {
    try {
      const res = await getDashboarddata();
      if (res.success) {
        setData(res.response.body);
      }
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
    }
  };

  const fetchWithToken = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.blob(); 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleUsersDownload = async () => {
    const response = await fetchWithToken(navigationUrls.users);
    if (response) {
      const blob = new Blob([response], { type: 'application/xls' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'users.xls';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const handleAppointmentDownload = async () => {
    const response = await fetchWithToken(navigationUrls.appointment);
    if (response) {
      const blob = new Blob([response], { type: 'application/xls' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'appointments.xls';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  if (!data) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const pieChartData = {
    labels: ['Appointments', 'Labs', 'Users'],
    datasets: [
      {
        data: [data.totalAppointment, data.totalLab, data.totalUser],
        backgroundColor: ['#8884d8', '#82ca9d', '#ffc658'],
        hoverOffset: 4
      }
    ]
  };

  const lineChartData = {
    labels: ['Daily', 'Weekly', 'Monthly', 'Yearly'],
    datasets: [
      {
        label: 'Appointments',
        data: [data.dailyTotalAppointment, data.weeklyTotalAppointment, data.monthlyTotalAppointment, data.yearlyTotalAppointment],
        borderColor: '#8884d8',
        backgroundColor: 'rgba(136, 132, 216, 0.2)',
        fill: true,
      },
      {
        label: 'Labs',
        data: [data.dailyTotalLab, data.weeklyTotalLab, data.monthlyTotalLab, data.yearlyTotalLab],
        borderColor: '#82ca9d',
        backgroundColor: 'rgba(130, 202, 157, 0.2)',
        fill: true,
      },
      {
        label: 'Users',
        data: [data.dailyTotalUser, data.weeklyTotalUser, data.monthlyTotalUser, data.yearlyTotalUser],
        borderColor: '#ffc658',
        backgroundColor: 'rgba(255, 198, 88, 0.2)',
        fill: true,
      }
    ]
  };

  return (
    <div>
      <Typography className="text-black" variant="h4" sx={{ mb: 5 }}>
        Hi, Welcome back 👋
      </Typography>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6} md={6} onClick={handleUsersDownload}>
          <AppWidgetSummary
            title="Total User"
            total={data.totalUser}
            color="error"
            icon={<img alt="icon" src={usericon} />}
          />
        </Grid>
        <Grid xs={12} sm={6} md={6} onClick={handleAppointmentDownload}>
          <AppWidgetSummary
            title="Total Appointments"
            total={data.totalAppointment}
            color="error"
            icon={<img alt="icon" src={usericon} />}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ mt: 5 }}>
        Statistics Overview
      </Typography>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid xs={12} md={6} container justifyContent="center">
          <Line data={lineChartData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat().format(context.parsed.y);
                    }
                    return label;
                  }
                }
              }
            }
          }} />
        </Grid>

        <Grid justifyContent="center">
          <Pie data={pieChartData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.label || '';
                    let value = context.raw || 0;
                    return `${label}: ${new Intl.NumberFormat().format(value)}`;
                  }
                }
              }
            }
          }} />
        </Grid>
      </Grid>
    </div>
  );
}
